import React, {useEffect} from 'react';
import Seo from '../config/seo';
import BaseLayout from '../layouts/BaseLayout';
import WhatWeDo from '../components/pages/what-we-do';

const WhatWeDoPage = () => {
    useEffect(() => {
        document.body.style.height = ``
    }, []);
    return (
        <>
            <Seo title="What We Do"
                 description="Learn about what we do"
            />
            <BaseLayout>
                <WhatWeDo/>
            </BaseLayout>
        </>
    )
}

export default WhatWeDoPage;
