import React, {useState, useRef, useEffect} from 'react'

const Video = props => {
    const {
        className = '',
        id,
        type,
        src,
        autoPlay,
        loop,
        fallbackSrc,
        fallbackType,
        animatedVideo,
        width,
        height
    } = props

    const videoElem = useRef(null)
    const [replayVideo, setReplayVideo] = useState(false)

    const rePlayVideo = () => {
        const threshold = 0.01
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.intersectionRatio > threshold) {
                    setReplayVideo(true)
                } else {
                    setReplayVideo(false)
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold,
            }
        )

        observer.observe(videoElem.current)

        if (replayVideo && animatedVideo) {
            const video = videoElem?.current
            video.currentTime = 0
            video.play()
        }
    }

    useEffect(() => {
        rePlayVideo()
    }, [replayVideo])

    return (
        <video
            autoPlay={autoPlay}
            muted
            loop={loop}
            playsInline
            width={width ? width : '100%'}
            height={height ? height : '100%'}
            className={className}
            id={id}
            ref={videoElem}
        >
            {fallbackSrc && (
                <source src={fallbackSrc} type={`video/${fallbackType}`}/>
            )}
            <source src={src} type={`video/${type}`}/>
        </video>
    )
}

export default Video
