import React from 'react';
import Layout from '../../../layouts/Layout';
import About from './About';
import LeftToRightMarquee from './LeftRightMarquee';
import Strategy from './Strategy';
import Chessboard from './Chessboard';
import Creative from './Creative';
import TopBottomMarquee from './TopBottomMarquee';
import Digital from './Digital';

const WhatWeDo = () => {
	return (
		<Layout id="whatWeDoMain" pageClass="wwd-page">
			<About/>
			<section className="darker-section">
				<Strategy/>
				<LeftToRightMarquee/>
			</section>
			<Creative/>
			<section className="dark-section">
				<Chessboard/>
				<Digital/>
				<TopBottomMarquee/>
			</section>
		</Layout>
	)
}

export default WhatWeDo
