import React from 'react'
import Observe from '../../elements/Observe'
import Image from '../../elements/Image'
import SafariDetect from '../../elements/SafariDetect'

const LeftToRightMarquee = () => {
	const data = []

	for (let i = 0; i < 120; i++) {
		data.push(i)
	}

	const elements = data.map(item => {
		return <Image key={Math.random()} src="/images/what-we-do/left-right.png" alt="left-right-image"/>
	})

	return (
		<SafariDetect>
			<section className="chessboard chessboard__1">
				<Observe>
					<div className="chessboard__marquee">
						<div className="chessboard__left-right">{elements}</div>
						<div className="chessboard__left-right">{elements}</div>
						<div className="chessboard__left-right">{elements}</div>
						<div className="chessboard__left-right">{elements}</div>
					</div>
					<div className="chessboard__description">
						<p className="quote quote--left">
							<Image src="/images/what-we-do/blue-mark.png" alt="blue-quote-mark"/>
						</p>
						<p className="quote quote--right">
							<Image src="/images/what-we-do/blue-mark.png" alt="blue-quote-mark"/>
						</p>
						<div className="chessboard__description--info scroll-reveal">
							<p>There is nothing so useless</p>
							<p>as doing efficiently that</p>
							<p>which should not be done at all.</p>
							<p>Peter Drucker</p>
						</div>
					</div>
				</Observe>
			</section>
		</SafariDetect>
	)
}

export default LeftToRightMarquee
