import React, {useState, useEffect} from 'react'
import Observe from '../../elements/Observe'
import Image from '../../elements/Image'
import aboutData from '../../../jsons/what-we-do.json'
import SafariDetect from '../../elements/SafariDetect'

const WhatWeDo = () => {
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 1000)

        return () =>
            setTimeout(() => {
                setIsLoaded(true)
            }, 1000)
    }, [])

    return (
        <SafariDetect>
            <section className={`wwd ${isLoaded ? 'loaded' : ''}`}>
                <Observe className="wwd-container">
                    <div className="wwd-content scroll-reveal">
                        <div className="wwd-description">
                            <h1 className="relative">
                                What we do <div className="line line-bottom yellow"/>
                            </h1>
                            <p>
                                We partner with companies and people that exemplify a passion for their brand and a
                                willingness to be different in a world diluted by monotony and similarity. We are here to
                                first understand you, then to work alongside you to bring your brand to the forefront of
                                consumers' minds. Transforming each product we touch into a conversational piece. This
                                requires emotion to be drawn out. Based on your brand's current needs, we provide a
                                targeted, intentional strategy to tell a cohesive and compelling story that leaves a lasting
                                impression. This drives brand loyalty. We want your ideal consumer to feel a comfort with
                                your brand one can only feel at home.
                            </p>
                        </div>
                    </div>
                    <p className="wwd-subtitle">Our Process</p>
                </Observe>

                <div className="wwd-letters">
                    {aboutData.about.map((item, index) => {
                        return (
                            <div key={`about-${index}`}>
                                <Image src={`/svgs/${item.image}`} alt={item.title}/>
                                <p>{item.title}</p>
                            </div>
                        )
                    })}
                </div>
            </section>
        </SafariDetect>
    )
}

export default WhatWeDo
