import React from 'react';
import Observe from '../../elements/Observe';
import EyeEffect from '../../elements/EyeEffect';
import SafariDetect from '../../elements/SafariDetect';
import Video from '../../elements/Video';

const Strategy = () => {
    return (
        <section className="offerings offerings__strategy">
            <Observe>
                <SafariDetect>
                    <EyeEffect className="dark">
                        <div className="offerings__container">
                            <div className="offerings__video animate-s">
                                <Observe>
                                    <Video
                                        animatedVideo={true}
                                        loop={false}
                                        autoPlay={true}
                                        className={'show-mob'}
                                        src={'https://player.vimeo.com/progressive_redirect/playback/777262004/rendition/720p/file.mp4?loc=external&signature=6c5e3cd350fd8a92c96f0e3fb62d720daf863faeeb2e75d1046757bdc5adc9b8'}
                                        type={'mp4'}
                                    />
                                    <Video
                                        animatedVideo={true}
                                        loop={false}
                                        autoPlay={true}
                                        className={'show-desk'}
                                        src={'https://player.vimeo.com/progressive_redirect/playback/777261980/rendition/1080p/file.mp4?loc=external&signature=dee6fa00b5e1767118c9485e6b0f4e6168fd38a2b1fd6460e40f59c78cfa67d6'}
                                        type={'mp4'}
                                    />
                                </Observe>
                            </div>
                            <div className="offerings__description">
                                <h4 className="relative">
                                    Strategy <span className="line line-bottom yellow"/>
                                </h4>
                                <p>
                                    Each strategy we build is unique and carefully crafted based upon your brand's DNA.
                                    For this reason, it is imperative for us to first deeply understand the intricacies
                                    of your brand. This requires intimate conversations. We seek to share the lens you
                                    view your business through, to understand the desires that drive your every move.
                                    Knowing the ideal end result helps us work backwards to construct an effective,
                                    pragmatic plan of action.
                                </p>
                                <ul>
                                    <li>Discovery</li>
                                    <li>Market Research</li>
                                    <li>Brand Strategy</li>
                                    <li>Digital Strategy</li>
                                    <li>Cash Flow Strategy</li>
                                </ul>
                            </div>
                        </div>
                    </EyeEffect>
                </SafariDetect>
            </Observe>
        </section>
    )
}

export default Strategy;
