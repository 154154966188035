import React from 'react'
import Observe from '../../elements/Observe'
import Image from '../../elements/Image'
import SafariDetect from '../../elements/SafariDetect'

const Chessboard = () => {
	const dataChess = []

	for (let i = 0; i < 8; i++) {
		dataChess.push(i)
	}

	const chessElements = dataChess.map(item => {
		return (
			<div className="chess--wrapper" key={Math.random()}>
				<Image src="/images/what-we-do/chess-img.png" alt="chess-square"/>
			</div>
		)
	})

	return (
		<SafariDetect>
			<section className="chessboard chessboard__2">
				<Observe>
					<div className="chessboard__chess">
						<div className="chess--container">{chessElements}</div>
						<div className="chess--container">{chessElements}</div>
						<div className="chess--container">{chessElements}</div>
						<div className="chess--container">{chessElements}</div>
						<div className="chess--container">{chessElements}</div>
					</div>
					<div className="chessboard__description">
						<p className="quote quote--left">
							<Image src="/images/what-we-do/yellow-mark.png" alt="yellow-quote-mark"/>
						</p>
						<p className="quote quote--right">
							<Image src="/images/what-we-do/yellow-mark.png" alt="yellow-quote-mark"/>
						</p>
						<div className="chessboard__description--info scroll-reveal">
							<p>However beautiful the strategy,</p>
							<p>you should occasionally</p>
							<p>measure the results.</p>
							<p>Winston Churchill</p>
						</div>
					</div>
				</Observe>
			</section>
		</SafariDetect>
	)
}
export default Chessboard
