import React, {useEffect, useRef, useState} from 'react';
import {gsap, ScrollTrigger} from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);

const EyeEffect = props => {
    const {
        children,
        className = ''
    } = props;

    const eye = useRef(null)
    const [eyeOpen, setEyeOpen] = useState(false)

    useEffect(() => {
        if (typeof window != 'undefined' && window.innerWidth >= 1024) {
            const triggerRefresh = () => {
                if (0 < window.scrollY) {
                    // ScrollTrigger.refresh()
                }
            }
            ScrollTrigger.create({
                trigger: eye.current,
                start: '5% 50%',
                toggleActions: 'play none none reverse',
                onEnter: () => {
                    setEyeOpen(true)
                    ScrollTrigger.refresh()
                },
                onEnterBack: () => {
                    setEyeOpen(true)
                    ScrollTrigger.refresh()
                },
                onLeave: () => {
                    setEyeOpen(false)
                },
                onLeaveBack: () => {
                    setEyeOpen(false)
                },
            })
            window.addEventListener('scroll', triggerRefresh)
            return () => {
                window.removeEventListener('scroll', triggerRefresh)
            }
        }
    }, [])

    return (
        <div
            className={`eye ${className}${eyeOpen ? ' eye__open' : ''}`}
            ref={eye}
        >
            {children}
        </div>
    )
}

export default EyeEffect
