import React from 'react'

import Observe from '../../elements/Observe'
import SafariDetect from '../../elements/SafariDetect'
import Image from '../../elements/Image'

const TopBottomMarquee = () => {
	const UpsideDownData = []

	for (let i = 0; i < 5; i++) {
		UpsideDownData.push(i)
	}

	const UpsideDownElements = UpsideDownData.map(item => {
		return <Image key={Math.random()} src="/images/what-we-do/top-bottom-marquee.png" alt="top-bottom-image"/>
	})
	return (
		<SafariDetect>
			<section className="chessboard chessboard__3">
				<Observe>
					<div className="chessboard__marquee">
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
						<div className="chessboard__upside-down">{UpsideDownElements}</div>
					</div>
					<SafariDetect>
						<div className="chessboard__description">
							<p className="quote quote--left">
								<Image src="/images/what-we-do/pink-mark.png" alt="pink-quote-mark"/>
							</p>
							<p className="quote quote--right">
								<Image src="/images/what-we-do/pink-mark.png" alt="pink-quote-mark"/>
							</p>
							<div className="chessboard__description--info scroll-reveal">
								<p>Creativity is seeing</p>
								<p>what everyone else has seen,</p>
								<p>and thinking what no one else has</p>
								<p>thought.</p>
								<p>Albert Einstein</p>
							</div>
						</div>
					</SafariDetect>
				</Observe>
			</section>
		</SafariDetect>
	)
}

export default TopBottomMarquee
