import React from 'react'
import Observe from '../../elements/Observe'
import EyeEffect from '../../elements/EyeEffect'
import SafariDetect from '../../elements/SafariDetect'
import Video from '../../elements/Video'

const Digital = () => {
    return (
        <section className="offerings offerings__digital">
            <Observe>
                <SafariDetect>
                    <EyeEffect className="dark">
                        <div className="offerings__container">
                            <div className="offerings__video animate-d">
                                <Observe>
                                    <Video
                                        animatedVideo={true}
                                        loop={false}
                                        autoPlay={true}
                                        className="show-mob"
                                        src={'https://player.vimeo.com/progressive_redirect/playback/778004673/rendition/1080p/file.mp4?loc=external&signature=a265efb453136d458e04b419c1aeaded9c63dd159bbe146821fae90018a8429f'}
                                        type="mp4"
                                    />
                                    <Video
                                        animatedVideo={true}
                                        loop={false}
                                        autoPlay={true}
                                        className="show-desk"
                                        src={'https://player.vimeo.com/progressive_redirect/playback/777261943/rendition/1080p/file.mp4?loc=external&signature=193c3059fd29fc174509961dd6abef777ae11ed2896dfa9a270c27bf7fda20b9'}
                                        type="mp4"
                                    />
                                </Observe>
                            </div>

                            <div className="offerings__description">
                                <h4 className="relative">
                                    Digital <span className="line line-bottom yellow"/>
                                </h4>
                                <p>
                                    We live in a digital age where messages are constantly conveyed on screens and
                                    formulated based on algorithms. Many brands are reaching for the same audiences, but
                                    each brand only has a finite amount of resources to communicate its message. We
                                    pride ourselves on providing optimized solutions for navigating the digital world of
                                    marketing and advertising. Every decision we make can be supported by data driven
                                    analytics, allowing us to evolve and grow synonymously with new technologies and
                                    consumer behavior.
                                </p>
                                <ul>
                                    <li>Paid Search</li>
                                    <li>Media Buying</li>
                                    <li>Email Marketing</li>
                                    <li>Paid Social Management</li>
                                    <li>Social Media Management</li>
                                </ul>
                            </div>
                        </div>
                    </EyeEffect>
                </SafariDetect>
            </Observe>
        </section>
    )
}

export default Digital
