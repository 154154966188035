import React from 'react'
import Observe from '../../elements/Observe'
import EyeEffect from '../../elements/EyeEffect'
import SafariDetect from '../../elements/SafariDetect'
import Video from '../../elements/Video'

const Creative = () => {
    return (
        <section className="offerings offerings__creative">
            <Observe>
                <SafariDetect>
                    <EyeEffect className="bright">
                        <div className="offerings__container">
                            <div className="offerings__video animate-c">
                                <Observe>
                                    <Video
                                        animatedVideo={true}
                                        loop={false}
                                        autoPlay={true}
                                        className="show-mob"
                                        src={'https://player.vimeo.com/progressive_redirect/playback/777261923/rendition/1080p/file.mp4?loc=external&signature=10a64cf63c18b4627917bb87112a347af09f24520933cf3971e34085970b30a9'}
                                        type="mp4"
                                    />
                                    <Video
                                        animatedVideo={true}
                                        loop={false}
                                        autoPlay={true}
                                        className="show-desk"
                                        src={'https://player.vimeo.com/progressive_redirect/playback/777261852/rendition/1080p/file.mp4?loc=external&signature=2948cb0aa50b6abdeeaef8f75a0e2dd84f0b239a294dc2c2feb94e66c360cabc'}
                                        type="mp4"
                                    />
                                </Observe>
                            </div>

                            <div className="offerings__description">
                                <h4 className="relative">
                                    Creative <span className="line line-bottom yellow"/>
                                </h4>
                                <p>
                                    The only boundaries we work within are the ones you set for us. From there, we
                                    create. We unlearn to relearn and undo to redo. We build brands without bounds and
                                    operate outside of the confines of what the world views as normal. We recognize
                                    market trends, then pioneer new ones. We ideate once inspired, striving to
                                    consistently design work that draws out emotion and spawns conversation.
                                </p>
                                <ul>
                                    <li>Copy Writing</li>
                                    <li>Brand Creation </li>
                                    <li>Graphic Design</li>
                                    <li>Content Creation</li>
                                    <li>Website Development</li>
                                    <li>Marketing Collateral</li>
                                    <li>Campaign Collateral</li>
                                </ul>
                            </div>
                        </div>
                    </EyeEffect>
                </SafariDetect>
            </Observe>
        </section>
    )
}

export default Creative
